<template>
  <v-container>
    <v-alert border="left" colored-border color="success" elevation="10">
    <v-icon x-large color="success">mdi-account-check</v-icon>
    Votre paiement a été accepté et la licence de votre professionnel a été validée.
    </v-alert>
  </v-container>
</template>

<script>
export default {
  components: {
  },
  data() {
    return {
      userId: ""
    };
  },
  methods: {},
  async mounted() {
    this.userId = this.$route.params.id;

    // const organization = await this.$services.organization.get();

    // this.organizationName = organization.data.name;
  },
};
</script>